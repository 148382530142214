import { Button as AscendButton } from '@achieve/ascend'
import { handleTrackAndReactEvent } from 'utils/analytics'
import { updateTrackWithListedEvent } from 'utils/analytics/trackingLibrary/updateTrackEventWithListedEvent'
import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { useEventScope } from 'hooks/useEventScope'

function Button(props) {
  let track = props.track
  const { fireAndForget } = useTriggerEvents()
  const specificity = useEventScope()

  if (track) {
    track = updateTrackWithListedEvent(track, specificity)
  }

  const localClickHandler = (event, track, onClick) => {
    fireAndForget({
      event_type: track?.event_type,
      ...handleTrackAndReactEvent(event, track, onClick),
    })
  }

  return props.defaultbutton ? (
    <button
      {...props}
      onClick={(event) => {
        localClickHandler(event, track, props.onClick)
      }}
    >
      {props.children}
    </button>
  ) : (
    <AscendButton
      {...props}
      onClick={(event) => {
        localClickHandler(event, track, props.onClick)
      }}
    >
      {props.children}
    </AscendButton>
  )
}

export { Button }
