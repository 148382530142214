import { useContext } from 'react'
import { LayoutContext } from 'providers/LayoutProvider'
import { isWindowReady, getWindowDetails } from 'utils/viewport'

const useViewportSize = () => {
  const { state: { viewportSize } = {} } = useContext(LayoutContext)
  // Normally, this hook takes the viewportSize data from the LayoutContext reducer. Due to initial
  // render timing, the viewportSize data is stale and defaults to a "best guess" based on user
  // agent. To improve initial render and minimize layout shift, when the default values are used
  // but window is ready, directly measure the window.
  if (!viewportSize.calculatedClient && isWindowReady()) {
    const manualCalculation = getWindowDetails()
    return manualCalculation
  }
  return viewportSize
}

export default useViewportSize
