import * as yup from 'yup'

/**
 * For usage see https://github.com/jquense/yup
 */

export const richTextSchema = yup.object({
  content: yup.array().of(yup.object()).required(),
  data: yup.object().required(),
  nodeType: yup.string().oneOf(['document']).required(),
})

export const imageFieldSchema = yup.object({
  url: yup.string().required(),
  height: yup.number().required(),
  width: yup.number().required(),
})

export const carouselItemSchema = yup.array().of(
  yup.object({
    cardImage: yup.object(),
    cardLink: yup.object(),
    cardText: yup.object(),
    cardTitleText: yup.object(),
  })
)

export const gritItemSchema = yup.array().of(
  yup.object({
    cardImage: yup.object().required(),
    cardLink: yup.object(),
    cardText: yup.object().required(),
    cardTitleText: yup.object(),
    cardTitleSubtitleText: yup.object(),
    fieldName: yup.string().required(),
  })
)

export const faqSetSchema = yup.array().of(
  yup.object({
    question: yup.object().required(),
  })
)

export const ctaButtonSchema = yup.object({
  linkHref: yup.string().required(),
  linkText: richTextSchema,
})

export const urlHeaderSchema = yup.object({
  fields: yup.object({
    name: yup.string().required(),
    linkHref: yup.string().required(),
  }),
})

export const urlFooterSchema = yup.object({
  url: yup.string().required(),
  label: yup.string().required(),
})
