import { GlobalContext } from 'providers/GlobalProvider'
import { useContext } from 'react'
import { BREAKPOINTS, maxBreakpoints } from 'constants/viewport'
import useViewportSize from 'hooks/useViewportSize'

/**
 * Check if window object is available
 */
const isWindowReady = () => typeof window !== 'undefined'

const getWindowDetails = () => {
  if (!isWindowReady()) {
    return
  }
  let result
  const { innerWidth: width, innerHeight: height } = window

  /**
   * pixelValues is a list of tuples containing the breakpoint abbreviation
   * as the key and the pixel number as the value, sorted in ascending
   * order so that a match can be found where the current screen width
   * is greater than pixelValues[i][1] and less than pixelValues[i + 1][1]
   */
  const pixelValues = [
    ['xxs', 0],
    ['xs', 320],
    ['sm', 480],
    ['md', 768],
    ['lg', 1024],
    ['xl', 1280],
    ['xxl', 1440],
  ]

  pixelValues.every(([key, value], i) => {
    if (width >= value && (i === pixelValues.length - 1 || width < pixelValues[i + 1][1])) {
      // break out of iteration once matching interval found
      result = {
        breakpointPixelSize: value,
        breakpointLetterSize: key,
        screenWidth: width,
        screenHeight: height,
        calculatedClient: true,
      }
      return false
    }
    // else continue iteration
    return true
  })
  return result
}

/**
 * Check to see if a viewport is larger than a given breakpoint
 * @param {string} [breakpoint='xxs'] - xxs, xs, sm, md, lg, xl, xxl
 * @returns boolean
 */
const useViewportLargerThan = (breakpoint = BREAKPOINTS.xxs) => {
  const { isMobileGlobal } = useContext(GlobalContext)
  return isMobileGlobal[`${breakpoint}Larger`]
}

/**
 * Check to see if a viewport is smaller than a given breakpoint
 * @param {string} [breakpoint='xxl'] - xxs, xs, sm, md, lg, xl, xxl
 * @returns boolean
 */
const useViewportSmallerThan = (breakpoint = BREAKPOINTS.xxl) => {
  const { isMobileGlobal } = useContext(GlobalContext)
  return isMobileGlobal[`${breakpoint}`]
}

/**
 * check all breakpoints regarding screen width
 * @returns object
 */
const useViewportCheckAllSizes = () => {
  const { screenWidth } = useViewportSize()
  return {
    xxs: screenWidth && screenWidth < maxBreakpoints.xxs,
    xs: screenWidth && screenWidth < maxBreakpoints.xs,
    sm: screenWidth && screenWidth < maxBreakpoints.sm,
    md: screenWidth && screenWidth < maxBreakpoints.md,
    lg: screenWidth && screenWidth < maxBreakpoints.lg,
    xl: screenWidth && screenWidth < maxBreakpoints.xl,
    xxl: screenWidth && screenWidth < maxBreakpoints.xxl,
    xxsLarger: screenWidth && screenWidth >= maxBreakpoints.xxs,
    xsLarger: screenWidth && screenWidth >= maxBreakpoints.xs,
    smLarger: screenWidth && screenWidth >= maxBreakpoints.sm,
    mdLarger: screenWidth && screenWidth >= maxBreakpoints.md,
    lgLarger: screenWidth && screenWidth >= maxBreakpoints.lg,
    xlLarger: screenWidth && screenWidth >= maxBreakpoints.xl,
    xxlLarger: screenWidth && screenWidth >= maxBreakpoints.xxl,
  }
}

export {
  getWindowDetails,
  isWindowReady,
  useViewportCheckAllSizes,
  useViewportLargerThan,
  useViewportSmallerThan,
}
