import { createContext, useEffect, useReducer } from 'react'
import { useViewportCheckAllSizes } from 'utils/viewport'
const GlobalContext = createContext()

const globalReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE_DISCLOSURE':
      return {
        ...state,
        pageDisclosure: action.payload,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const GlobalProvider = ({ pageDisclosure, children }) => {
  // TODO: Add deep comparison of pageDisclosure vs state.pageDisclosure and only dispatch if different
  useEffect(() => {
    if (pageDisclosure) {
      dispatch({ type: 'SET_PAGE_DISCLOSURE', payload: pageDisclosure })
    }
  }, [pageDisclosure])

  const isMobile = useViewportCheckAllSizes()
  const [state, dispatch] = useReducer(globalReducer, {
    pageDisclosure: pageDisclosure,
  })
  return (
    <GlobalContext.Provider value={{ isMobileGlobal: isMobile, ...state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  )
}

export { GlobalProvider, GlobalContext }
