export const PRD_ENV = 'prd'
export const STG_ENV = 'stg'
export const DEV_ENV = 'dev'
export const SBX_ENV = 'sbx'

export const PRD_URL = 'https://www.achieve.com'
export const STG_URL = 'https://achieve.stg.ffngcp.com'
export const DEV_URL = 'https://achieve.dev.ffngcp.com'
export const SBX_URL = 'https://achieve.sbx.ffngcp.com'

export const ENV_TO_URL = {
  [PRD_ENV]: PRD_URL,
  [STG_ENV]: STG_URL,
  [DEV_ENV]: DEV_URL,
  [SBX_ENV]: SBX_URL,
}
