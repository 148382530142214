import { useState, useEffect } from 'react'

/**
 * React hook to check if code is being run on the server
 * See: https://stephencook.dev/blog/using-window-in-react-ssr/
 */
const useIsSsr = () => {
  // we always start off in "SSR mode", to ensure our initial browser render
  // matches the SSR render
  const [isSsr, setIsSsr] = useState(true)

  useEffect(() => {
    // `useEffect` never runs on the server, so we must be on the client if
    // we hit this block
    setIsSsr(false)
  }, [])

  return isSsr
}

export default useIsSsr
